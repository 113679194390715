import React, { Component } from 'react';
import { SpeechRecognitionService, effectsService } from '../../../../common/services';
import './SayThisGame.css';
import { find, sample } from 'lodash';
import { colorItemImages } from '../../../../common/assets/images';

const IMAGE_DISPLAY_TIME_MS = 5000;
const IMAGE_FADEOUT_TIME_MS = 500;

const colors = [
  {
    words: ['красный'],
    color: 'red',
    images: colorItemImages.red,
  },
  {
    words: ['синий'],
    color: 'blue',
    images: colorItemImages.blue,
  },
  {
    words: ['зелёный', 'зеленый'],
    color: 'green',
    images: colorItemImages.green,
  },
  {
    words: ['жёлтый', 'желтый'],
    color: 'yellow',
    images: colorItemImages.yellow,
  },
  {
    words: ['белый'],
    color: 'white',
    images: [],
  },
  {
    words: ['чёрный', 'черный'],
    color: 'black',
    images: [],
  },
];
export class SayThisGame extends Component {
  imageTimeout: unknown = 0;

  state = {
    text: '',
    bgColor: 'transparent',
    picture: '',
    pictureHidden: true,
  }

  speechRecognitionService = new SpeechRecognitionService();

  componentDidMount() {
    this.speechRecognitionService.addEventListener('result', this.handleResult);
  }

  componentWillUnmount() {
    this.speechRecognitionService.stop();
  }

  colorFromText(text: string): string | void {
    const words = text.split(' ').reverse();
    for (const word of words) {
      const colorEntry = colors.find(color => color.words.includes(word));
      if (colorEntry) {
        return colorEntry.color;
      }
    }
  }

  handleResult = (event: any) => {

    const text = event.detail.transcript.trim().toLocaleLowerCase();
    const bgColor = this.colorFromText(text);

    if (!bgColor) {
      return;
    }

    if (bgColor !== this.state.bgColor) {
      effectsService.sound('levelClose');

      this.setState({
        bgColor,
        pictureHidden: true,
      });

      clearTimeout(this.imageTimeout as number);
      this.imageTimeout = null;
    }

    if (!this.imageTimeout) {
      this.imageTimeout = setTimeout(() => {
        clearTimeout(this.imageTimeout as number);
        this.imageTimeout = null;
        const colorEntry = find(colors, { color: bgColor });
        if (colorEntry) {
          const picture = sample(colorEntry.images);
          this.setState({
            picture: '',
          });

          this.setState({
            picture,
            pictureHidden: false,
          });
          this.imageTimeout = setTimeout(() => {
            this.setState({ pictureHidden: true });
            this.imageTimeout = setTimeout(() => {
              this.setState({ picture: '' });
              this.imageTimeout = null;
            }, IMAGE_FADEOUT_TIME_MS);
          }, IMAGE_DISPLAY_TIME_MS);
        }
      }, IMAGE_FADEOUT_TIME_MS);
    }

    this.setState({ text });
  }


  render() {
    return (
      <div className="game game-saythis">
        <div className="color-pane" style={{ backgroundColor: this.state.bgColor }}>
          <div className="speech-text-block">{this.state.text}</div>
          {this.state.picture ?
            <img className={this.state.pictureHidden ? 'image-block hidden': 'image-block'} src={this.state.picture} alt="level"/> : null
          }
        </div>
      </div>
    );
  }
}
