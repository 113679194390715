import React, { Component } from 'react';

import './GridListComponent.css';

export class GridList extends Component<any> {
  render() {
    const columnCount = this.props.columns || '2';
    const containerClass = `grid-list columns-${columnCount}`;

    return (
      <div className={containerClass}>
        {this.props.children}
      </div>
    );
  }
}
