class ConfigService {

  languageIDs: { [key: string]: string } = {
    ru: 'ru-RU',
    en: 'en-US',
    pl: 'pl-PL',
  };

  defaultLanguage = 'en';

  getLevelSetId() {
    return 'ANIMALS';
  }
  getLanguageList() {
    return Object.keys(this.languageIDs);
  }

  getLanguageId(): string {
    const lang = this.getLanguage();
    return this.languageIDs[lang] || this.languageIDs[this.defaultLanguage];
  }

  getLanguage(): string {
    return globalThis.localStorage.getItem('imguess.lng') || this.defaultLanguage;
  }

  setLanguage(lng: string) {
    globalThis.localStorage.setItem('imguess.lng', lng);
  }

  isMuted(): boolean {
    return Boolean(globalThis.localStorage.getItem('imguess.mute'));
  }
  getNoVibrate(): boolean {
    return Boolean(globalThis.localStorage.getItem('imguess.noVibrate'));
  }
  setMute(value: boolean) {
    globalThis.localStorage.setItem('imguess.mute', value ? 'true' : '');
  }
  setNoVibrate(value: boolean) {
    globalThis.localStorage.setItem('imguess.noVibrate', value ? 'true' : '');
  }

  getMusicVolume() {
    return 0.6;
  }
}

export const configService = new ConfigService();