// <Card link="/games/saythis" caption="SayThis" picture=""></Card>

import { GameModule } from '../../types/GameModule';
import { Sudoku } from './components/Sudoku';

export const SudokuModule: GameModule = {
  id: 'sudoku',
  component: Sudoku,
  card: {
    caption: 'Sudoku',
    link: 'sudoku',
    picture: '',
  },
  description: `Always wanted to know who is best in Sudoku you or your friend?
  Try a classic Sudoku game with a multiplayer twist.
  Make turns one by one or just play the same puzzle head to head.
  At the end you can compare overall time and errors made`,
};