import React, { Component } from 'react';

import './SettingsPage.css';
import { configService } from '../../../gameModules/imguess/services/configService';
import { effectsService } from '../../../common/services/effectsService';

export class SettingsPage extends Component<any, any> {

  state = {
    currentLanguage: configService.getLanguage(),
    languageList: configService.getLanguageList(),
    sound: !configService.isMuted(),
    vibration: !configService.getNoVibrate(),
  }

  componentDidMount() {
    this.getConfigData();
  }

  getConfigData() {
    this.setState({
      currentLanguage: configService.getLanguage(),
      languageList: configService.getLanguageList(),
      sound: !configService.isMuted(),
      vibration: !configService.getNoVibrate(),
    });
  }

  setLanguage(language: string) {
    configService.setLanguage(language);
    this.getConfigData();
  }

  goBack = () => {
    this.props.history.goBack();
  }

  setMute = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isMuted = !e.target.checked;
    configService.setMute(isMuted);
    if (isMuted) {
      effectsService.musicOff();
    }
    this.getConfigData();
  }

  doReload = () => {
    // @ts-expect-error
    window.location.reload(true);
    // window.location.href = window.location.href;
  }

  setVibrate = (e: React.ChangeEvent<HTMLInputElement>) => {
    configService.setNoVibrate(!e.target.checked);
    this.getConfigData();
  }

  render() {
    const { languageList, currentLanguage, sound, vibration } = this.state;
    return (
      <div className="settings-page">
        <div className="settings-title">Settings</div>
        <div className="language-bar">
          {languageList.map((language: string) => (
            <label key={language}>
              <span>{language}</span>
              <input
                name={language}
                type="checkbox"
                checked={language === currentLanguage}
                onChange={() => this.setLanguage(language)}
              />
            </label>
          ))}
        </div>
        <div className="effects-bar">
          <label>
            <span>Sound</span>
            <input
              type="checkbox"
              checked={sound}
              onChange={this.setMute}
            />
          </label>
          <label>
            <span>Vibration</span>
            <input
              type="checkbox"
              checked={vibration}
              onChange={this.setVibrate}
            />
          </label>
        </div>
        <div className="button-panel">
          <form action="/" method="POST">
            <button className="reload-button" type="submit">Reload</button>
          </form>
          <button className="close-button" onClick={this.goBack}>OK</button>
        </div>
      </div>
    );
  }
}
