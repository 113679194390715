import { configService } from '../../gameModules/imguess/services/configService';

export class SpeechRecognitionService extends EventTarget {

  private recognition: SpeechRecognition | undefined;

  constructor() {
    super();
    this.init();
  }

  stop() {
    if (this.recognition) {
      this.recognition.removeEventListener('result', this.handleResult as EventListener);
      this.recognition.removeEventListener('error', this.handleError);
      this.recognition.removeEventListener('speechend', this.restart);
      this.recognition.removeEventListener('nomatch', this.restart);
      this.recognition.stop();
      this.recognition.abort();
    }
  }

  handleError = () => {
    this.restart();
  }

  handleResult = (event: SpeechRecognitionEvent) => {
    const result = event.results[event.resultIndex];
    const isFinal = result.isFinal;

    this.dispatchEvent(new CustomEvent('result', {
      detail: {
        isFinal,
        transcript: result[0].transcript,
      }
    }));
  }

  restart = () => {
    setTimeout(() => {
      this.stop();
      this.init();
    });
  }

  init() {
    const SR = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    this.recognition = new SR();
    if (!this.recognition) {
      return;
    }
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.maxAlternatives = 0;
    this.recognition.lang = configService.getLanguage();
    this.recognition.addEventListener('result', this.handleResult as EventListener);
    this.recognition.addEventListener('error', this.handleError);
    this.recognition.addEventListener('speechend', this.restart);
    this.recognition.addEventListener('nomatch', this.restart);
    this.recognition.start();
  }
}
