import React, { useLocation, useNavigate, useParams } from 'react-router-dom';

// HOC that injects route params as props
export const withRouterParams = (Component: any) => {
  const Wrapped = (props: any) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    return <Component {...props} {...params} navigate={navigate} location={location}/>;
  };
  return Wrapped;
};