
import { alea } from 'seedrandom';
import { createPuzzle, removeNumbers } from './createPuzzle';
import { Cell } from '../types';

type Difficulty = 'easy' | 'medium' | 'hard';

const emptyCells: Record<Difficulty, number> = {
  easy: 35,
  medium: 45,
  hard: 55,
};


export class SudokuGame {
  public key = 0;
  public solution: number[][];
  public initialState: number[][];
  public board: number[][];
  public seed: string;
  public duration = 0;
  private history: Cell[] = [];
  public startTime: number = Date.now();

  public state: 'playing' | 'won' = 'playing';


  constructor(private difficulty: Difficulty = 'hard', seed?: string) {
    this.seed = seed || Math.random().toString(10).substring(2, 5);
    const random = alea(this.seed);
    this.solution = createPuzzle(random);
    this.initialState = this.solution.map(row => row.slice());
    removeNumbers(this.initialState, emptyCells[this.difficulty], random);
    this.board = this.initialState.map(row => row.slice());
  }

  isKnown = (cell?: Cell | null): boolean => {
    if (!cell) {
      return false;
    }
    return this.initialState[cell[1]][cell[0]] > 0;
  }

  isIncorrect = (cell: Cell): boolean => {
    const value = this.board[cell[1]][cell[0]];
    const correctValue = this.solution[cell[1]][cell[0]];
    return !!value && value !== correctValue;
  }

  move(cell: Cell | null, num: number): void {
    if (!cell || this.isKnown(cell)) {
      return;
    }

    this.key = this.key + 1;
    this.board[cell[1]][cell[0]] = num;
    this.history.push(cell);

    if (this.board.toString() === this.solution.toString()) {
      this.state = 'won';
      this.duration = this.getDuration();
    }
  }

  getCell = (cell: Cell | null): number => {
    if (!cell) {
      return 0;
    }
    return this.board[cell[1]][cell[0]];
  }

  getDuration = (): number => {
    return this.duration || Math.round((Date.now() - this.startTime) / 1000);
  }
}