import React, { FC, useEffect } from 'react';
import './App.css';
import { Route, Navigate, Routes } from 'react-router-dom';
import { GamesMenu, SettingsPage, SettingsIcon } from '../';
import { effectsService } from '../../common/services/effectsService';
import { BackButton } from '../shared/BackButton/BackButton';

export const App: FC = () => {
  useEffect(() => {
    effectsService.musicOn('anticipationSlowed');
  }, []);
  return (
    <div className="App">
      <BackButton/>
      <Routes>
        <Route path="/" element={<Navigate to="/games/imguess"/>} />
        <Route path="/games/*" element={<GamesMenu />}/>
        <Route path="/settings" element={<SettingsPage />}/>
      </Routes>
    </div>
  );
};

