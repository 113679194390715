import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

// import { SayThisGame, NumbersGame } from '../../gameModules';
import { GridList, Card } from '..';
import { gameModules } from '../../gameModules';

import './GamesMenu.css';


export const GamesMenu: FC = () => {
  return (
    <div className="games-menu">
      <Routes>
        <Route path="/" element={
          <GridList>
            {gameModules.map(game => <Card key={game.id} {...game.card}/>)}
          </GridList>
        }>
        </Route>

        {gameModules.map(game => <Route
          key={game.id}
          path={`${game.card.link}/*`}
          element={<game.component />}
        />)}
      </Routes>
    </div>
  );
};

