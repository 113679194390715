import elephant from './elephant.png';
import monkey from './monkey01.png';
import dog from './dog.png';
import stinkBug from './stink-bug.png';
import zebra from './zebra01.png';
import bear from './bear.png';
import hog from './hog.png';
import horse from './horse.png';
import hedgehog from './hedgehog.png';
import lion from './lion.png';
import alligator from './alligator.png';
import owl from './owl.png';
import fox from './fox.png';
import giraffe from './giraffe.png';
import sheep from './sheep.png';
import snail from './snail.png';
import cow from './cow.png';
import bull from './bull.png';
import kitty from './kitty.png';
import goat from './goat.png';
import rat from './rat.svg';
import earthworm from './earthworm.svg';
import housefly from './housefly.svg';
import turtle from './turtle.svg';
import octopus from './octopus.svg';
// import shark from './shark.svg';
import pigeon from './pigeon.svg';
import chicken from './chicken.svg';
import dolphin from './dolphin.svg';
import seastar from './seastar.svg';
import crab from './crab.svg';

export const animalImages = {
  elephant,
  monkey,
  dog,
  stinkBug,
  zebra,
  bear,
  hog,
  horse,
  hedgehog,
  lion,
  alligator,
  owl,
  fox,
  giraffe,
  sheep,
  snail,
  cow,
  bull,
  kitty,
  goat,
  rat,
  earthworm,
  housefly,
  turtle,
  octopus,
  // shark,
  pigeon,
  chicken,
  dolphin,
  seastar,
  crab,
};