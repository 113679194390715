// <Card link="/games/saythis" caption="SayThis" picture=""></Card>

import { GameModule } from '../../types/GameModule';
import { SayThisGame } from './components/SayThisGame/SayThisGame';

export const SayThisModule: GameModule = {
  id: 'saythis',
  component: SayThisGame,
  card: {
    caption: 'SayThis',
    link: 'saythis',
    picture: '',
  }
};