import { SPEECH_RATE, SPEECH_PITCH } from '../../config/constants';
import { SOUND_EFFECTS } from '../assets/sounds/index';
import { configService } from '../../gameModules/imguess/services/configService';

const SOUNDS: { [key: string]: HTMLAudioElement } = {
  correct: new Audio(SOUND_EFFECTS.correct),
  wrong: new Audio(SOUND_EFFECTS.wrong),
  wordComplete: new Audio(SOUND_EFFECTS.completeDing),
  levelClose: new Audio(SOUND_EFFECTS.positiveDing),
  click: new Audio(SOUND_EFFECTS.click),
  anticipationSlowed: new Audio(SOUND_EFFECTS.anticipationSlowed),
};

const VIBRATE_EFFECTS: { [key: string]: number | number[] } = {
  endlevel: [200, 40, 10, 40, 10],
  short: [50, 10, 20],
  wordcomplete: [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 100, 100],
};

class EffectsService {

  musicSoundId: string | null = null;

  constructor() {
    document.addEventListener('visibilitychange', () => {
      const tabActive = document.visibilityState !== 'hidden';
      if (this.musicSoundId) {
        if (tabActive) {
          SOUNDS[this.musicSoundId].play();
        }
        if (!tabActive) {
          SOUNDS[this.musicSoundId].pause();
        }
      }
    });

    //
    speechSynthesis.getVoices();
  }

  async sound(effect: string) {
    if (configService.isMuted()) {
      return null;
    }
    const sound = SOUNDS[effect];
    sound.currentTime = 0;
    try {
      await sound.play();
    } catch {
      // no user interaction yet
      return null;
    }
    return sound;
  }

  vibrate(effectName: string) {
    if (configService.getNoVibrate()) return;
    return navigator.vibrate(VIBRATE_EFFECTS[effectName]);
  }

  speak(text: string) {
    const voices = speechSynthesis.getVoices();
    const lang = configService.getLanguage();
    const googleVoice = voices.find(v => v.lang.includes(lang) && v.name.includes('Google'));
    const fallbackVoice = voices.find(v => v.lang.includes(lang));
    const voice = googleVoice || fallbackVoice;

    const uter = new SpeechSynthesisUtterance(text);
    uter.rate = SPEECH_RATE;
    uter.pitch = SPEECH_PITCH;
    uter.lang = configService.getLanguageId();

    if (voice) {
      uter.voice = voice;
    }
    speechSynthesis.speak(uter);
  }

  async musicOn(melodyId: string) {
    if (melodyId === this.musicSoundId) {
      return;
    }

    this.musicOff();
    const sound = await this.sound(melodyId);
    if (!sound) {
      return;
    }
    this.musicSoundId = melodyId;
    sound.loop = true;
    sound.volume = configService.getMusicVolume();
  }

  musicOff() {
    if (this.musicSoundId) {
      // TODO: add fadeOut
      SOUNDS[this.musicSoundId].pause();
      this.musicSoundId = null;
    }
  }

}

export const effectsService = new EffectsService();