import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ICON, Icon } from '../Icon/Icon';
import './BackButton.css';

export const BackButton = () => {
  const currentPage = useLocation();
  const currentPath = currentPage.pathname.split('/');
  currentPath.pop();
  const parentUrl = currentPath.join('/');

  return (
    <div className="back-button">
      <Link to={parentUrl}>
        <Icon icon={ICON.back} />
      </Link>
    </div>
  );
};