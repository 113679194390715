import React, { FC } from 'react';

type GameControlsProps = {
  selectedCell?: number | null;
  selectedNumber?: number | null;
  onClick?: (num: number) => void;
  disabled?: boolean;
};

export const GameControls: FC<GameControlsProps> = ({ onClick, disabled }) => {
  return (
    <div className="game-controls">
      <div className="number-list">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
          <button
            disabled={disabled}
            key={num}
            onClick={() => onClick?.(num)}>{num}</button>
        ))}
      </div>
    </div>);
};
