import React, { FC, useCallback, useEffect, useState } from 'react';
import { SudokuGame } from '../math/SudokuGame';
import { GameBoard } from './GameBoard';
import '../SudokuStyles.css';
import { Cell } from '../types';
import { GameControls } from './GameControls';

export const Sudoku: FC = () => {
  const [, setGameKey] = useState(0);
  const reRender = () => setGameKey(key => key + 1);

  const [seed, setSeed] = useState(() => Math.random().toString(10).substring(2, 5));
  const [difficulty, setDifficulty] = useState<'easy' | 'medium' | 'hard'>('hard');
  const [game, setGame] = useState(() => new SudokuGame());
  useEffect(() => {
    setGame(new SudokuGame(difficulty, seed || '0'));
  }, [seed, difficulty]);

  const [selected, setSelected] = useState<Cell | null>(null);

  const handleSelect = useCallback((cell: Cell) => {
    if (cell?.[0] === selected?.[0] && cell?.[1] === selected?.[1]) {
      setSelected(null);
      return;
    }
    setSelected(cell);
  }, []);

  const handleNumberClick = (num: number) => {
    if (game.getCell(selected) === num) {
      game.move(selected, 0);
    } else {
      game.move(selected, num);
    }
    reRender();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      reRender();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <div className="sudoku-root">
    <header className="sudoku-header">
      <div>Puzzle #
        <input type="number" value={seed} onChange={evt => setSeed(evt.target.value || '')} />

        <select value={difficulty} onChange={evt => setDifficulty(evt.target.value as 'easy' | 'medium' | 'hard')}>
          <option value="easy">easy</option>
          <option value="medium">medium</option>
          <option value="hard">hard</option>
        </select>
      </div>
      <div >
        <span className={`state-${game.state}`}>{game.state}</span>{' '}
        <span>{game.getDuration()}s</span>
      </div>
    </header>
    <GameBoard
      key={game.key}
      grid={game.board}
      onSelect={handleSelect}
      selected={selected}
      isKnown={game.isKnown}
      isIncorrect={game.isIncorrect}
    />
    <GameControls onClick={handleNumberClick} disabled={game.state === 'won' || !selected || game.isKnown(selected)}/>
  </div>;
};