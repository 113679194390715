import React, { FC } from 'react';
import { Cell } from '../types';

type GameBoardProps = {
  grid: number[][];
  onSelect: (cell: Cell) => void;
  selected?: Cell | null;
  isKnown: (cell: Cell) => boolean;
  isIncorrect: (cell: Cell) => boolean;
};

export const GameBoard: FC<GameBoardProps> = ({ grid, selected, onSelect, isKnown, isIncorrect }) => {
  const getCellClass = (cell: Cell): string => {
    const classes = ['cell'];

    if (selected?.[0] === cell[0] && selected?.[1] === cell[1]) {
      classes.push('selected');
    }

    if (isKnown(cell)) {
      classes.push('known');
    }

    if (isIncorrect(cell)) {
      classes.push('incorrect');
    }

    return classes.join(' ');
  };
  return (
    <div className="game-board">
      {grid.map((row, rowIndex) => (
        <div key={rowIndex} className="row">
          {row.map((cell, colIndex) => (
            <button
              key={`${colIndex}-${cell}`}
              type="button"
              className={getCellClass([colIndex, rowIndex])}
              onClick={() => onSelect([colIndex, rowIndex])}>
              {cell === 0 ? '' : cell}
            </button>
          ))}
        </div>)
      )}
    </div>
  );
};