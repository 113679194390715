import { imguessImages } from '../../common/assets';
import { GameModule } from '../../types/GameModule';
import { ImguessGame } from './components/ImguessGame/ImguessGame';

export const ImguessModule: GameModule = {
  id: 'imguess',
  component: ImguessGame,
  card: {
    caption: 'Imguess',
    link: 'imguess',
    picture: imguessImages.bear,
  },
  description: 'Guess the image letter by letter. Pick a language from list or mail me to add a new one.',
};
