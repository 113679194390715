import React, { Component } from 'react';

import './LevelComponent.css';
import { levelService } from '../../services/levelService';
import { configService } from '../../services/configService';
import { effectsService } from '../../../../common/services/effectsService';
import { LevelData } from '../../config';
import { COMPLETE_LEVEL_DELAY_MS, REDIRECT_LEVEL_DELAY_MS, MAX_WORD_SAY_TIME } from '../../../../config';
import { Letter } from '../Letter/Letter';
import { withRouterParams } from '../../../../components/withRouterParams';
import { NavigateFunction } from 'react-router-dom';

interface PropsType {
  navigate: NavigateFunction;
  location: Location;
  levelId: string;
}

interface LetterOption {
  value: string;
  open: boolean;
  offset: number;
}

interface StateType {
  level: LevelData;
  word: string;
  currentIndex: number;
  letterOptions: LetterOption[];
  completed: boolean;
  started: boolean;
  wordRepeatsLeft: number;
}

class OldLevel extends Component<PropsType, StateType> {

  componentDidMount() {
    const { levelId } = this.props;
    const level = levelService.getLevel(levelId);
    levelService.appendLevelHistory(levelId);

    if (!level) {
      return;
    }

    const word = level.word[configService.getLanguage()];
    const shuffledWord = levelService.randomizeWord(word);
    const letterOptions = shuffledWord.split('').map(value => ({ value, open: true, offset: 0 }));

    this.setState({
      level,
      letterOptions,
      word,
      currentIndex: 0,
      completed: false,
      started: false,
      wordRepeatsLeft: 0,
    });

    setTimeout(() => this.setState({ started: true }));
    // this.speechRecognitionService.addEventListener('result', this.handleResult as EventListener);
  }

  handleResult = (event: CustomEvent) => {
    const text = event.detail.transcript;
    const words: string[] = text.split(' ').filter(Boolean);

    const letter = (words[0] ?? '').toUpperCase();

    const index = this.state.letterOptions.findIndex(option => option.open && option.value === letter);
    this.guessLetter(letter, index);
  }

  completeLevel() {
    this.setState({ completed: true });
    effectsService.vibrate('wordcomplete');
    effectsService.sound('wordComplete');
    setTimeout(() => {
      effectsService.speak(this.state.word);
      this.setState({ wordRepeatsLeft: MAX_WORD_SAY_TIME });
    }, COMPLETE_LEVEL_DELAY_MS);
  }

  handleWordClick = () => {
    if (this.state.wordRepeatsLeft) {
      effectsService.sound('click');
      effectsService.speak(this.state.word);
      this.setState({ wordRepeatsLeft: this.state.wordRepeatsLeft - 1 });
    }
  }

  handleLetterClick = (guessIndex: number) => {
    effectsService.musicOn('anticipationSlowed');
    effectsService.vibrate('short');
    const guessedLetter = this.state.letterOptions[guessIndex].value;
    this.guessLetter(guessedLetter, guessIndex);
  }

  guessLetter(guessedLetter: string, guessIndex: number) {
    const currentLetter = this.state.word[this.state.currentIndex];
    if (guessedLetter === currentLetter) {
      effectsService.sound('correct');
      const letterOptions = this.state.letterOptions.map((option, index) => (index === guessIndex) ? {
        ...option,
        offset: this.state.currentIndex - guessIndex,
        open: false,
      } : option);

      const currentIndex = this.state.currentIndex + 1;

      if (!this.state.completed && currentIndex === this.state.word.length) {
        this.completeLevel();
      }

      this.setState({
        currentIndex,
        letterOptions,
      });
      return;
    }

    effectsService.sound('click');
  }

  handleLevelClick = () => {
    // ensure that music is playing
    effectsService.musicOn('anticipationSlowed');

    if (this.state.completed && this.state.started) {
      this.setState({
        started: false,
      });
      effectsService.vibrate('endlevel');
      effectsService.sound('levelClose');
      setTimeout(() => {
        this.props.navigate('/games/imguess/random');
      }, REDIRECT_LEVEL_DELAY_MS);

      return;
    }

    if (!this.state.completed) {
      effectsService.sound('click');
    }
  }

  render() {
    if (!this.state) return null;
    const { level, letterOptions, completed, started } = this.state;

    return (
      <div className={`level ${completed ? 'completed' : ''} ${started ? 'started' : ''}`}>
        <div className="image-row" onMouseDown={this.handleLevelClick} onTouchStart={this.handleLevelClick}>
          <div className="image-container" style={{ backgroundImage: `url(${level.picture})`}}>
          </div>
        </div>

        <div className="cells-row" onClick={this.handleWordClick} >
          {letterOptions.map((letter, index) =>
            <Letter
              key={index}
              open={letter.open}
              value={letter.value}
              onClick={() => this.handleLetterClick(index)}
              offset={letter.offset}
              index={index}
            />
          )}
        </div>
      </div>
    );
  }
}

export const Level = withRouterParams(OldLevel);
