import { LEVEL_DATA } from '../config/levels';
import { configService } from './configService';

class LevelService {
  private history: string[] = [];

  getLevelSet() {
    return LEVEL_DATA[configService.getLevelSetId()];
  }

  getLevel(levelId: string) {
    return this.getLevelSet().find(({ id }) => id === levelId);
  }

  getRandomLevel() {
    const levelSet = this.getLevelSet();

    let sample = levelSet[0];
    let sampleWeight = 0;

    levelSet.forEach(level => {
      // multiplier is higher for levels which are farther in history or
      // maxed for items with is not in history at all
      const historyMultiplier = this.history.includes(level.id) ? this.history.indexOf(level.id) : levelSet.length;

      // levelWeight is a random number but once we apply multiplier, more recent
      // levels have less changes to appear again
      const weight = historyMultiplier * Math.random();

      if (weight > sampleWeight) {
        sampleWeight = weight;
        sample = level;
      }
    });

    return sample;
  }

  appendLevelHistory(levelId: string) {
    this.history = [levelId, ...this.history];
  }

  private shuffle(word: string): string {
    const allLetters = word.split('');
    let result = '';
    while (allLetters.length) {
      const index = Math.floor(Math.random() * allLetters.length);
      result = result + allLetters.splice(index, 1)[0];
    }
    return result;
  }

  private fineShuffled(source: string, result: string): boolean {
    return source !== result;
  }

  randomizeWord(word: string): string {
    let result: string;
    do {
      result = this.shuffle(word);
    } while(!this.fineShuffled(word, result));
    return result;
  }

}

export const levelService = new LevelService();
