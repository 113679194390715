import React from 'react';
import { Link, Route, Navigate, Routes, Outlet } from 'react-router-dom';

import './ImguessGame.css';

import { Level } from '../Level/LevelComponent';
import { levelService } from '../../services/levelService';
import { imguessImages } from '../../../../common/assets';

export const ImguessGame = () => {
  const levels = [{
    id: 'random',
    picture: imguessImages.random,
  },
  ...levelService.getLevelSet()];

  const RandomLevelRedirect = () => {
    // Generate a new random level ID
    const randomLevelId = levelService.getRandomLevel().id;

    // Redirect to the new random level URL
    return <Navigate to={`/games/imguess/${randomLevelId}`} />;
  };

  return (
    <div className="game game-imguess">
      <Routes>
        <Route key="random" path="random" element={<RandomLevelRedirect />}/>

        <Route path="/" element={
          <div className="level-list">
            {levels.map(level => (
              <Link to={`/games/imguess/${level.id}`} key={level.id} className='level-card-wrapper'>
                <div className="level-card" style={{
                  backgroundImage: `url(${level.picture})`
                }}>
                </div>
              </Link>
            ))}
          </div>
        }
        />

        <Route path=":levelId" element={<Level />} />

      </Routes>
      <Outlet />
    </div>
  );
};

