import React, { Component } from 'react';
import { SpeechRecognitionService } from '../../../../common/services';
import './NumbersGame.css';
import { effectsService } from '../../../../common/services/effectsService';
import { GridList, Card } from '../../../../components';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

const numbers: { [key: string]: any } = {
  0: { words: ['ноль', 'zero'] },
  1: { words: ['один', 'one'] },
  2: { words: ['два', 'two'] },
  3: { words: ['три', 'three'] },
  4: { words: ['четыре', 'four'] },
  5: { words: ['пять', 'five'] },
  6: { words: ['шесть', 'six'] },
  7: { words: ['семь', 'seven'] },
  8: { words: ['восемь', 'eight'] },
  9: { words: ['девять', 'nine'] },
};

export class NumbersGame extends Component {
  fadeawayTimeout: any = 0;

  state: any = {
    activeNumbers: [],
  }

  speechRecognitionService = new SpeechRecognitionService();

  componentDidMount() {
    this.speechRecognitionService.addEventListener('result', this.handleResult);
  }

  componentWillUnmount() {
    this.speechRecognitionService.removeEventListener('result', this.handleResult);
    this.speechRecognitionService.stop();
  }

  handleResult = (event: any) => {
    console.log(event.detail);

    const text = event.detail.transcript;
    const words = text.split(' ').filter(Boolean);

    const numberHasWordsFromText = (n: string) => numbers[n].words.some((word: string) => words.includes(word));
    const foundDigits = Object.keys(numbers).filter(num => text.includes(num) || numberHasWordsFromText(num));

    const activeNumbers: any = {};

    foundDigits.forEach(digit => activeNumbers[digit] = this.state.activeNumbers[digit] || this.getRandomColor());
    if (!isEqual(Object.keys(activeNumbers), Object.keys(this.state.activeNumbers))) {
      effectsService.sound('wrong');
    }

    this.setState({ activeNumbers });

  }

  fadeAway = () => {
    effectsService.sound('levelClose');
    this.setState({ activeNumbers: {} });
  }

  getRandomColor = () => {
    return `#${Math.floor(Math.random() * 256).toString(16)}${Math.floor(Math.random() * 256).toString(16)}${Math.floor(Math.random() * 256).toString(16)}`;
  }

  handleClick = (event: MouseEvent) => {
    const numElement = event.target as HTMLDivElement;
    const clickedNumber: string = (numElement.closest('.number-card') as HTMLDivElement).dataset.number || '';

    if (this.state.activeNumbers[clickedNumber]) {
      this.setState({ activeNumbers: omit(this.state.activeNumbers, [clickedNumber]) });
      effectsService.sound('levelClose');
      // effectsService.speak(`${clickedNumber}`);
    }
  }

  render() {
    const { activeNumbers } = this.state;
    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    return (
      <div className="game game-numbers">
        <GridList columns="3">
          {numbers.map(num => (
            <div data-number={num} key={num} className={activeNumbers[num] ? 'number-card active' : 'number-card'} style={{ color: activeNumbers[num]}}>
              <Card caption={num} onClick={this.handleClick}></Card>
            </div>
          ))}

        </GridList>
      </div>
    );
  }
}
