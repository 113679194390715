import React, { Component } from 'react';
import './Icon.css';

export const ICON: { [key: string]: number } = {
  gear: 0,
  back: 1,
  mic: 2,
};

const iconCount = Object.keys(ICON).length;
export interface IconProps {
  icon: number;
}

export class Icon extends Component<IconProps> {

  render() {
    return (
      <div className="icon" style={{
        backgroundPositionX: `${Math.round(100 / (iconCount - 1) * this.props.icon)}%`,
      }}>

      </div>
    );
  }
}

