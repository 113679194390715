import { NumbersModule } from './numbers/index';
import { ImguessModule } from './imguess';
import { SayThisModule } from './saythis';
import { SudokuModule } from './sudoku';

export const gameModules = [
  ImguessModule,
  SayThisModule,
  NumbersModule,
  SudokuModule,
];