import React, { Component, CSSProperties } from 'react';
import './Letter.css';

interface Props {
  open: boolean;
  value: string;
  offset: number;
  index: number;
  onClick?: () => void;
}

export class Letter extends Component<Props> {
  render() {
    const { open, value, offset } = this.props;

    const translateX = Math.floor(offset * 100);
    const translateY = open ? 0 : -100;

    const letterStyle: CSSProperties = {
      transform: `translate(${translateX}%, ${translateY}%)`,
    };
    return (
      <div className="letter-container">
        <div className="letter-box letter-box-blank letter-box-top">
        </div>
        <div
          className={`letter-box letter-box-bottom ${open ? '' : 'letter-box-blank'}`}
          onMouseDown={open ? this.props.onClick : () => false}
          onTouchStart={open ? this.props.onClick : () => false}
        >
        </div>
        <span className="letter" style={letterStyle}>{value}</span>
      </div>
    );
  }
}
