import { imguessImages } from '../../../common/assets/images';

export const ANIMALS = [
  {
    id: 'monkey',
    word: {
      ru: 'ОБЕЗЬЯНА',
      en: 'MONKEY',
      pl: 'MAŁPA',
    },
    picture: imguessImages.monkey,
  },
  {
    id: 'elephant',
    word: {
      ru: 'СЛОН',
      en: 'ELEPHANT',
      pl: 'SŁOŃ',
    },
    picture: imguessImages.elephant,
  },
  {
    id: 'dog',
    word: {
      ru: 'СОБАКА',
      en: 'DOG',
      pl: 'PIES',
    },
    picture: imguessImages.dog,
  },
  {
    id: 'stinkBug',
    word: {
      ru: 'ВОНЮЧКА',
      en: 'STINKBUG',
      pl: 'CHRZĄSZCZ',
    },
    picture: imguessImages.stinkBug,
  },
  {
    id: 'zebra',
    word: {
      ru: 'ЗЕБРА',
      en: 'ZEBRA',
      pl: 'ZEBRA',
    },
    picture: imguessImages.zebra,
  },
  {
    id: 'bear',
    word: {
      ru: 'МЕДВЕДЬ',
      en: 'BROWNBEAR',
      pl: 'NIEDŹWIEDŹ',
    },
    picture: imguessImages.bear,
  },
  {
    id: 'hog',
    word: {
      ru: 'СВИНЬЯ',
      en: 'HOG',
      pl: 'DZIK',
    },
    picture: imguessImages.hog,
  },
  {
    id: 'horse',
    word: {
      ru: 'ЛОШАДКА',
      en: 'HORSE',
      pl: 'KOŃ',
    },
    picture: imguessImages.horse,
  },
  {
    id: 'hedgehog',
    word: {
      ru: 'ЕЖОНОК',
      en: 'HEDGEHOG',
      pl: 'JEŻ',
    },
    picture: imguessImages.hedgehog,
  },
  {
    id: 'lion',
    word: {
      ru: 'ЛЬВЁНОК',
      en: 'LION',
      pl: 'LEW',
    },
    picture: imguessImages.lion,
  },
  {
    id: 'alligator',
    word: {
      ru: 'КРОКОДИЛ',
      en: 'ALLIGATOR',
      pl: 'ALIGATOR',
    },
    picture: imguessImages.alligator,
  },
  {
    id: 'owl',
    word: {
      ru: 'СОВА',
      en: 'OWL',
      pl: 'SOWA',
    },
    picture: imguessImages.owl,
  },
  {
    id: 'fox',
    word: {
      ru: 'ЛИСИЦА',
      en: 'FOX',
      pl: 'LISEK',
    },
    picture: imguessImages.fox,
  },
  {
    id: 'giraffe',
    word: {
      ru: 'ЖИРАФ',
      en: 'GIRAFFE',
      pl: 'ŻYRAFA',
    },
    picture: imguessImages.giraffe,
  },
  {
    id: 'sheep',
    word: {
      ru: 'ОВЦА',
      en: 'SHEEP',
      pl: 'OWCA',
    },
    picture: imguessImages.sheep,
  },
  {
    id: 'snail',
    word: {
      ru: 'УЛИТКА',
      en: 'SNAIL',
      pl: 'ŚLIMAK',
    },
    picture: imguessImages.snail,
  },
  {
    id: 'cow',
    word: {
      ru: 'КОРОВА',
      en: 'COW',
      pl: 'KROWA',
    },
    picture: imguessImages.cow,
  },
  {
    id: 'bull',
    word: {
      ru: 'БЫК',
      en: 'BULL',
      pl: 'BYK',
    },
    picture: imguessImages.bull,
  },
  {
    id: 'kitty',
    word: {
      ru: 'КОШКА',
      en: 'KITTY',
      pl: 'KOTEK',
    },
    picture: imguessImages.kitty,
  },
  {
    id: 'goat',
    word: {
      ru: 'КОЗА',
      en: 'GOAT',
      pl: 'KOZA',
    },
    picture: imguessImages.goat,
  },
  {
    id: 'rat',
    word: {
      ru: 'КРЫСА',
      en: 'RAT',
      pl: 'SZCZUR',
    },
    picture: imguessImages.rat,
  },
  {
    id: 'earthworm',
    word: {
      ru: 'ЧЕРВЯК',
      en: 'EARTHWORM',
      pl: 'DŻDŻOWNICA',
    },
    picture: imguessImages.earthworm,
  },
  {
    id: 'housefly',
    word: {
      ru: 'МУХА',
      en: 'FLY',
      pl: 'MUCHA',
    },
    picture: imguessImages.housefly,
  },
  {
    id: 'turtle',
    word: {
      ru: 'ЧЕРЕПАХА',
      en: 'TURTLE',
      pl: 'ŻÓŁW',
    },
    picture: imguessImages.turtle,
  },
  {
    id: 'octopus',
    word: {
      ru: 'ОСЬМИНОГ',
      en: 'OCTOPUS',
      pl: 'OŚMIORNICA',
    },
    picture: imguessImages.octopus,
  },
  // {
  //   id: 'shark',
  //   word: {
  //     ru: 'АКУЛА',
  //     en: 'SHARK',
  //   },
  //   picture: imguessImages.shark,
  // },
  {
    id: 'pigeon',
    word: {
      ru: 'ГОЛУБЬ',
      en: 'PIGEON',
      pl: 'GOŁĄB',
    },
    picture: imguessImages.pigeon,
  },
  {
    id: 'chicken',
    word: {
      ru: 'ЦЫПЛЕНОК',
      en: 'CHICKEN',
      pl: 'KURCZĄTKO',
    },
    picture: imguessImages.chicken,
  },
  {
    id: 'dolphin',
    word: {
      ru: 'ДЕЛЬФИН',
      en: 'DOLPHIN',
      pl: 'DELFIN',
    },
    picture: imguessImages.dolphin,
  },
  {
    id: 'seastar',
    word: {
      ru: 'ЗВЕЗДА',
      en: 'SEASTAR',
      pl: 'ROZGWIAZDA',
    },
    picture: imguessImages.seastar,
  },
  {
    id: 'crab',
    word: {
      ru: 'КРАБ',
      en: 'CRAB',
      pl: 'KRAB',
    },
    picture: imguessImages.crab,
  },
];