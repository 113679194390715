import { GameModule } from '../../types/GameModule';
import { NumbersGame } from './components/NumbersGame/NumbersGame';

export const NumbersModule: GameModule = {
  id: 'numbers',
  component: NumbersGame,
  card: {
    caption: 'Numbers',
    link: 'numbers',
    picture: '',
  },
  description: 'A simple toy to teach kids to pronounce numbers. Say the number and see it on the screen',
};