import React, { Component, TouchEvent, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import './Card.css';

export interface CardProps {
  link?: string;
  caption?: string;
  picture?: string;
  onClick?: (evt: any) => void;
}

export class Card extends Component<CardProps> {
  handleClick = (event: TouchEvent | MouseEvent) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }
  render() {
    const cardStyle = this.props.picture ? { backgroundImage: `url(${this.props.picture})` } : undefined;
    let item = (
      <div onClick={this.handleClick} onTouchStart={this.handleClick} className="card-block" style={cardStyle}>
        <div className="card-caption">
          <div className="card-caption-text">{this.props.caption}</div>
        </div>
      </div>
    );

    if (this.props.link) {
      item = (
        <Link to={this.props.link}>{item}</Link>
      );
    }
    return (item);
  }
}
