import random from './random.png';
import { animalImages } from './animals';
export * from './color_items';

export const imguessImages = {
  ...animalImages,
  random,
};


