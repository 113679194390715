import { ANIMALS } from './animals';
export interface LevelData {
  id: string;
  word: {
    [language: string]: string;
  };
  picture: string;
}

export const LEVEL_DATA: { [key: string]: LevelData[] } = {
  ANIMALS
};

