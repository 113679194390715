export type Grid = number[][];

const createEmptyGrid = (): Grid => {
  return Array(9).fill(null).map(() => Array(9).fill(0));
};

const findEmptyCell = (grid: Grid): [number, number] | null => {
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (grid[row][col] === 0) return [row, col];
    }
  }
  return null;
};

const isValid = (grid: Grid, row: number, col: number, num: number): boolean => {
  for (let x = 0; x < 9; x++) {
    if (grid[row][x] === num) return false;
  }

  // Check column
  for (let x = 0; x < 9; x++) {
    if (grid[x][col] === num) return false;
  }

  // Check 3x3 sub-grid
  const startRow = Math.floor(row / 3) * 3;
  const startCol = Math.floor(col / 3) * 3;
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 3; j++) {
      if (grid[startRow + i][startCol + j] === num) return false;
    }
  }

  return true;
};

const shuffleArray = (array: number[], random: () => number): number[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const fillGrid = (grid: Grid, random: () => number): boolean => {
  const emptyCell = findEmptyCell(grid);
  if (!emptyCell) {
    return true;
  }

  const [row, col] = emptyCell;
  const numbers = shuffleArray([1, 2, 3, 4, 5, 6, 7, 8, 9], random);

  for (const num of numbers) {
    if (isValid(grid, row, col, num)) {
      grid[row][col] = num;
      if (fillGrid(grid, random)) return true;
      grid[row][col] = 0;
    }
  }

  return false;
};


export const createPuzzle = (random: () => number): Grid => {
  const grid = createEmptyGrid();
  fillGrid(grid, random);
  return grid;
};

export const removeNumbers = (board: Grid, numHoles: number, random: () => number): Grid =>  {
  while (numHoles > 0) {
    const row = Math.floor(random() * 9);
    const col = Math.floor(random() * 9);
    if (board[row][col] !== 0) {
      board[row][col] = 0;
      numHoles--;
    }
  }
  return board;
};